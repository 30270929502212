<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-layout class="patient-list" column>
    <v-card class="rounded-xl overflow-hidden" :disabled="disableWhileLoading">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">{{ getpatientN + "s" }}</div>
        <v-spacer />
        <template v-if="selected.length != 0">
          <v-dialog
            persistent
            v-model="transferDialog"
            transition="dialog-top-transition"
            max-width="600"
          >
            <template v-slot:activator="{ on: dialog }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn icon color="#76FF03" v-on="{ ...dialog, ...tooltip }">
                    <v-icon>mdi-account-network</v-icon></v-btn
                  >
                </template>
                <span>Create Relationship</span>
              </v-tooltip>
            </template>
            <template>
              <v-card>
                <v-toolbar color="primary" dark>Select Employee</v-toolbar>
                <v-card-text>
                  <div class="text-h2 pt-4 px-4 d-flex">
                    <v-select
                      v-model="employeeUuidt"
                      label="Employees"
                      :items="workers"
                      prepend-inner-icon="mdi-doctor"
                      item-text="fullname"
                      item-value="uuid"
                      :loading="loadingOcup"
                      hide-details
                      clearable
                      outlined
                      dense
                    />
                    <v-select
                      class="ml-1"
                      :disabled="employeeUuidt == '' || employeeUuidt == null"
                      v-model="relation.ocupationId"
                      label="Occupations"
                      :items="ocupations"
                      item-text="name"
                      item-value="id"
                      :loading="loadingOcup"
                      hide-details
                      outlined
                      dense
                    />
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="closedTransfer">Close</v-btn>
                  <v-btn
                    :disabled="
                      employeeUuidt == '' ||
                      employeeUuidt == null ||
                      loadingAction
                    "
                    color="primary"
                    :loading="loadingAction"
                    text
                    @click="transfer"
                    >Confirm</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>
        <template v-if="selected.length != 0">
          <v-dialog
            persistent
            v-model="deleteDialog"
            transition="dialog-top-transition"
            max-width="600"
          >
            <template v-slot:activator="{ on: dialog }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn icon color="error" v-on="{ ...dialog, ...tooltip }">
                    <v-icon>mdi-close-network</v-icon></v-btn
                  >
                </template>
                <span>Delete Relationship</span>
              </v-tooltip>
            </template>
            <template>
              <v-card>
                <v-toolbar color="error" dark>Select Employee</v-toolbar>
                <v-card-text>
                  <div class="text-h2 pt-4 px-4 d-flex">
                    <v-select
                      v-model="employeeUuidt"
                      label="Employees"
                      :items="workers"
                      prepend-inner-icon="mdi-doctor"
                      item-text="fullname"
                      item-value="uuid"
                      :loading="loadingOcup"
                      hide-details
                      clearable
                      outlined
                      dense
                    />
                    <v-select
                      class="ml-1"
                      :disabled="employeeUuidt == '' || employeeUuidt == null"
                      v-model="relation.ocupationId"
                      label="Occupations"
                      :items="ocupations"
                      item-text="name"
                      item-value="id"
                      :loading="loadingOcup"
                      hide-details
                      outlined
                      dense
                    />
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="closedTransfer">Close</v-btn>
                  <v-btn
                    :disabled="
                      employeeUuidt == '' ||
                      employeeUuidt == null ||
                      loadingAction
                    "
                    color="error"
                    :loading="loadingAction"
                    text
                    @click="deleteRelation"
                    >Delete</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>

        <v-btn :loading="loading" text @click="getPatients">
          <v-icon>mdi-autorenew</v-icon>
          {{ $t("labelUpdate") }}
        </v-btn>
      </v-toolbar>

      <div class="d-flex align-center pa-3">
        {{/* Filter */}}
        <v-switch
          label="No Relationship"
          v-model="noCoordinator"
          class="mx-1"
          @change="getPatients()"
          inset
        ></v-switch>
        <v-text-field
          v-model.trim="query"
          :label="'Search or filter ' + getpatientN"
          hide-details
          clearable
          outlined
          dense
        />
        <v-select
          v-if="isAdmins"
          v-model="employeeUuid"
          label="Filter by Employee"
          :items="workers"
          class="mx-2"
          prepend-inner-icon="mdi-doctor"
          item-text="fullname"
          item-value="uuid"
          :loading="loading"
          hide-details
          clearable
          outlined
          dense
          @change="getPatients"
        />
        <v-select
          v-model="statusId"
          label="Filter by Status"
          :items="states"
          prepend-inner-icon="mdi-magnify"
          item-text="label"
          item-value="id"
          :loading="loadingState"
          hide-details
          clearable
          outlined
          dense
          @change="getPatients"
        />
      </div>
      <v-divider />

      <v-data-table
        v-model="selected"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalpatient"
        :items="patients"
        :show-select="isAdmin || isSuper || isSupervisor"
        :loading="disableWhileLoading"
        :footer-props="footerProps"
        item-key="uuid"
        @click:row="showDetails"
      >
        <template v-slot:[`item.fullname`]="{ item }">
          <span class="namepatient"
            >{{ item.fullname }}
            <template v-if="getNotification(item)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="notificationdocument"
                    color="warning"
                    small
                    >mdi-information</v-icon
                  >
                </template>
                <span>This patient has signed documents without review</span>
              </v-tooltip>
            </template>
          </span>
        </template>
        <template v-slot:[`item.isMale`]="{ item }">
          {{ item.isMale ? $t("labelMale") : $t("labelFemale") }}
        </template>
        <template v-slot:[`item.laststatus`]="{ item }">
          <template v-if="getStatus(item)">
            <span v-bind:style="{ color: getStatus(item).color }">{{
              getStatusN(item)
            }}</span>
          </template>
          <template v-else> - </template>
        </template>

        <not-records slot="no-data" />
      </v-data-table>
      <v-layout justify-center>
        <errors v-model="showErrors" class="mt-3" :message="errorMessage" />
      </v-layout>
    </v-card>

    {{/* Confirm remove */}}
  </v-layout>
</template>

<script>
import Vue from "vue";
import NotRecords from "@/components/NotRecords.vue";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import Errors from "../PdfSign/Errors.vue";
import { AxiosError, AxiosResponse } from "axios";
import BtnRequestPatients from "./buttons/BtnRequestPatients.vue";
import PatientFilter from "./PatientFilter.vue";
import Patient from "@/models/Patient";
import { debounce, templateSettings } from "lodash";

export default Vue.extend({
  name: "crm-patients",
  components: {
    NotRecords,
    Errors,
    BtnRequestPatients,
    /*   PatientFilter, */
  },
  data() {
    return {
      transferDialog: false,
      deleteDialog: false,
      loadingOcup: false,
      loadingAction: false,
      ocupations: [],
      employeeUuidt: "",
      relation: {
        employeeUuid: "",
        ocupationId: 0,
        patientUuids: [],
        create: true,
      },

      signature: new Blob(),
      loadingSignStep1: false,
      showErrors: false,
      options: {},
      reqrange: {
        limit: 100,
        offset: 0,
      },
      errorMessage: "",
      loadingSendStep: false,
      footerProps: { "items-per-page-options": [10, 15, 25, 50, 100] },
      selected: [],
      expanded: [],
      // The uuid of the worker to remove
      currentPatient: null,
      dialogEdit: false,

      dialogDocument: false,
      dialogSignStep1: false,
      states: [],
      loadingState: false,
      query: null,
      statusId: null,
      noCoordinator: false,
      employeeUuid: null,
      loadingQuery: false,
      isAdmins: false,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", [
      "patients",
      "loading",
      "totalpatient",
      "doctors",
      "agents",
    ]),
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getRequireNote",
      "isSupervisor",
      "getCoordinatorN",
      "getSellerN",
      "getpatientN",
      "getviewpatientStatus",
    ]),
    ...mapState("crmEmployeeModule", ["workers", "coordinators"]),

    disableWhileLoading() {
      return this.loading || this.loadingSignStep1 || this.loadingSendStep;
    },

    headers() {
      if (this.getviewpatientStatus) {
        return [
          { text: this.$t("Fullname"), value: "fullname", sortable: false },
          { text: this.$t("labelGender"), value: "isMale", sortable: false },
          { text: "Email", value: "email", align: "start", sortable: false },
          {
            text: this.$t("labelHomePhone"),
            value: "homePhone",
            align: "start",
            sortable: false,
          },
          { text: "Status", value: "laststatus", sortable: false },
        ];
      }
      return [
        { text: this.$t("Fullname"), value: "fullname", sortable: false },
        { text: this.$t("labelGender"), value: "isMale", sortable: false },
        { text: "Email", value: "email", align: "start", sortable: false },
        {
          text: this.$t("labelHomePhone"),
          value: "homePhone",
          align: "start",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    employeeUuidt(val) {
      if (val != "" && val != null) {
        const worker = this.workers.filter((w) => w.uuid == val)[0];
        this.relation.ocupationId = worker.ocupation.id;
        this.relation.employeeUuid = val;
      } else {
        this.relation.ocupationId = "";
        this.relation.employeeUuid = "";
      }
    },
    options(val) {
      if (val != {}) {
        this.getPatients();
      }
    },
    query(val) {
      if (val == null) {
        this.getPatients();
      } else if (val.length >= 3 || val.length == 0) {
        this.getPatients();
      }
    },
  },
  destroyed() {
    localStorage.removeItem("filterfrompatients");
  },
  async mounted() {
    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      this.isAdmins = true;
      await this.actGetEmployees();
      await this.actGetCoordinators();
      this.loadingOcup = true;
      await this.listOcupations();
      this.loadingOcup = false;
    }
    this.getStatusList();
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListPatients",
      "actRemovePatient",
      "actGetPatientAgents",
      "actGetPatientDoctors",
      "actFilterPatient",
    ]),
    ...mapActions("crmEmployeeModule", [
      "actGetEmployees",
      "actGetCoordinators",
    ]),
    ...mapMutations("crmMedicFormModule", ["mutSetPatients"]),
    ...mapMutations(["mutReqRange"]),
    async listOcupations() {
      this.ocupations = (await getAPI(`/sprocedures/listNom/Ocupation`)).data;
    },
    getPatients() {
      this.selected = [];
      if (
        localStorage.getItem("filterfrompatients") &&
        localStorage.getItem("filtersPatients")
      ) {
        const tempdata = JSON.parse(localStorage.getItem("filtersPatients"));
        this.options.page = tempdata.page;
        this.options.itemsPerPage = tempdata.itemsPerPage;
        this.query = tempdata.query;
        this.statusId = tempdata.statusId;
        this.employeeUuid = tempdata.employeeUuid;
        this.noCoordinator = tempdata.noCoordinator;
      }

      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      this.reqrange = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      this.mutReqRange(this.reqrange);

      localStorage.setItem(
        "filtersPatients",
        JSON.stringify({
          query: this.query,
          statusId: this.statusId,
          employeeUuid: this.employeeUuid,
          noCoordinator: this.noCoordinator,
          page,
          itemsPerPage,
        })
      );
      localStorage.removeItem("filterfrompatients");
        
      this.actFilterPatient({
        query: this.query,
        statusId: this.statusId,
        range: this.reqrange,
        noCoordinator: this.noCoordinator,
        employeeUuid: this.employeeUuid,
      });
    },

    setPatientToSendDocument(patient) {
      this.currentPatient = patient;
      this.dialogDocument = true;
    },
    sendStep(step, patientUuid) {
      this.loadingSendStep = true;
      getAPI.post(`/patient/sendstep${step}/${patientUuid}`).then(() => {
        notifyInfo("The documents have been sended");
        this.loadingSendStep = false;
      });
    },
    showSignDialog(patient) {
      this.signature = new Blob();
      this.currentPatient = patient;
      this.dialogSignStep1 = true;
    },
    signAdminStep1() {
      const formData = new FormData();

      this.loadingSignStep1 = true;
      formData.append(
        "firma",
        this.signature,
        `signature-${this.currentPatient.uuid}`
      );
      getAPI({
        method: "post",
        url: "/patient/uploadAdminSign/" + this.currentPatient.uuid,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          this.loadingSignStep1 = false;
          this.dialogSignStep1 = false;
        })
        .catch((error) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);

          this.loadingSignStep1 = false;
          this.dialogSignStep1 = false;
        });
    },
    showDetails(patient) {
      this.$router.push(`/patients/details/${patient.uuid}`);
    },

    getStatus(patient) {
      const proceduresTemp = patient.proceduresEntity
        ? patient.proceduresEntity
        : [];
      if (proceduresTemp.length != 0) {
        return proceduresTemp[proceduresTemp.length - 1].statusNom;
      }
      return null;
    },
    getStatusN(patient) {
      const proceduresTemp = patient.proceduresEntity
        ? patient.proceduresEntity
        : [];
      if (proceduresTemp.length != 0) {
        return proceduresTemp[proceduresTemp.length - 1].statusNom
          ? proceduresTemp[proceduresTemp.length - 1].statusNom.name
          : "-";
      }
      return "-";
    },

    getNotification(patient) {
      let c = 0;
      if (patient == undefined || patient == null) {
        return;
      }
      if (patient.envelopes) {
        const envelopesPatient = patient.envelopes.filter(
          (env) => env.deleted === false
        );
        if (envelopesPatient.length != 0) {
          envelopesPatient.forEach((element) => {
            if (
              element.read === false &&
              element.lastDocusStatus === "completed"
            ) {
              c++;
            }
          });
        }
      }

      const proceduresPatient = patient.proceduresEntity.filter(
        (p) => p.activeStatus === "ACTIVE"
      );
      proceduresPatient.forEach((element) => {
        if (element.envelope) {
          const envelopesProcedure = element.envelope.filter(
            (eleenv) => eleenv.deleted === false
          );
          envelopesProcedure.forEach((envP) => {
            if (envP.read === false && envP.lastDocusStatus === "completed") {
              c++;
            }
          });
        }
      });
      if (c != 0) {
        return true;
      } else {
        return false;
      }
    },
    transfer() {
      this.loadingAction = true;
      const body = Object.assign({}, this.relation);
      const patientTransfer = this.selected.map((patient) => patient.uuid);
      body.patientUuids = patientTransfer;
      body.employeeUuid = this.employeeUuidt;
      getAPI
        .post("/patient/createOrDeleteMultipleRelations", body)
        .then(() => {
          notifySuccess(this.$t("Patients Relationship has been created"));
          this.getPatients();
          this.closedTransfer();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAction = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    deleteRelation() {
      this.loadingAction = true;
      const body = Object.assign({}, this.relation);
      const patientTransfer = this.selected.map((patient) => patient.uuid);
      body.employeeUuid = this.employeeUuidt;
      body.create = false;
      body.patientUuids = patientTransfer;
      getAPI
        .post("/patient/createOrDeleteMultipleRelations", body)
        .then(() => {
          notifyInfo(this.$t("Patients Relationship has been deleted"));
          this.getPatients();
          this.closedTransfer();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAction = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    closedTransfer() {
      this.transferDialog = false;
      this.deleteDialog = false;
      this.employeeUuidt = "";
      this.loadingOcup = false;
      this.loadingAction = false;
      this.relation = {
        employeeUuid: "",
        ocupationId: 0,
        patientUuids: [],
        create: true,
      };
    },

    async getStatusList() {
      this.loadingState = true;
      const response = (await getAPI(`/procedure-status-nom/list`)).data;
      this.loadingState = false;
      const filt = response.filter((r) => r.status != "NO_ACTIVE");

      this.states = filt.map((res) => {
        return {
          label: res.name.replaceAll("_", " "),
          value: res.name,
          id: res.id,
        };
      });
    },
  },
});
</script>

<style lang="scss">
.patient-list {
  .v-data-table {
    tbody {
      tr {
        cursor: pointer !important;
      }
    }
  }
}
.namepatient {
  position: relative;
}
.notificationdocument {
  position: absolute;
  top: -5px;
  right: 3px;
}
</style>

<i18n>
{
  "en": {
    "Fullname": "Full name",
    "dialogTitle": "Reason why you open the details of :",
    "notification": "Successfully removed",
    "labelGender": "Gender",
    "labelHomePhone": "Phone",
    "labelEmergencyPhone": "Emergency phone"

  },
  "es": {
    "Fullname": "Nombre completo",
    "dialogTitle": "Razon por la que abre los detalles de :",
    "notification": "Eliminado con éxito",
    "labelGender": "Género",
    "labelHomePhone": "Teléfono",
    "labelEmergencyPhone": "Teléfono de emergencias"
  }
}
</i18n>
